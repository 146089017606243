import { type FunctionComponent } from 'react';
import { JumpTab, JumpTabs } from '../../../components/common-components/jump-tabs/jump-tabs.component';
import { type ContentComponentHelper } from '../../../components/construct-components/render-helper/render.helper';
import { type LandingPageContent } from '../../../types/construct.types';

export type SaleSectionListProps = {
	content: ContentComponentHelper<LandingPageContent>;
};

export const SaleSectionList: FunctionComponent<SaleSectionListProps> = ({ content }) => {
	const {
		content: {
			fields: { sale_section }
		}
	} = content;

	return (
		<JumpTabs>
			{sale_section.items.map((section, index) => {
				const { nav_title = '' } = section.fields;
				return (
					<JumpTab key={index} tabName={nav_title} tabId={`section-tab-${index}`}>
						{content.render(section)}
					</JumpTab>
				);
			})}
		</JumpTabs>
	);
};
